.history-menu-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 16rem;
}

.tabslistspeedkino-container {
    display: flex;
    width: 100%;
}

.tabslistspeedkino {
    // background-color: rgba(0, 86, 166, 1);
    background: rgb(130, 97, 64);
    // color: rgb(255, 223, 189) !important;
}

.tabslistspeedkino1 {
    // background-color: rgba(255, 255, 255, 1);
    // border-width: 1px;
    // border-color: rgba(183, 183, 183, 1);
    // border-style: solid;
    background: rgb(30, 30, 30);
    border: 0.1875rem solid rgb(85, 85, 85);
}

.tabslistspeedkino2 {
    width: 50%;
    border-radius: 3rem;
    font-family: 'SpoqaHanSansNeoMedium';
    letter-spacing: -0.07rem;
    padding-right: 0.4rem;
}

.tabslistspeedkino3 {
    color: #ffdfbd;
}

.tabslistspeedkino4 {
    color: rgb(204, 194, 182);
}

.tabslistspeedkino5 {
    font-family: 'SpoqaHanSansNeoMedium';
}

.speedkino-path {
    margin-top: 2rem;
    font-family: 'SpoqaHanSansNeoMedium';
    letter-spacing: -0.025em;
    color: #ccc2b6;

    .speedkino-path1 {
        display: flex;
        width: 100%;

        .speedkino-path2 {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            background-color: #252525;
        }
    }
}

.cells-speedkino {
    width: 100%;
    font-family: 'SpoqaHanSansNeoMedium';
    font-size: 14px;
    letter-spacing: -0.025em;
}

.speedkino-sum {
    margin-top: 2rem;
    font-family: 'SpoqaHanSansNeoMedium';
    letter-spacing: -0.025em;
    color: #ccc2b6;

    .speedkino-sum1 {
        display: flex;
        width: 100%;

        .speedkino-sum2 {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            background-color: #252525;
        }
    }
}

.cells-speedkino1 {
    width: 100%;
    font-family: 'SpoqaHanSansNeoMedium';
    font-size: 14px;
    letter-spacing: -0.025em;
}

.container-cells {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom-style: solid;
    border-color: #252525;
    font-family: 'SpoqaHanSansNeo';
    letter-spacing: -0.025em;
    color: #c8c8c8;

    .content-cells {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;

        .content-cells1 {
            position: relative;
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            border-radius: 9999px;
            padding-top: 0.3rem;
            padding-right: 0.2rem;
            font-family: 'SpoqaHanSansNeoMedium';
            letter-spacing: -0.025em;
            color: rgba(255, 255, 255, 1);

            .content-cells2 {
                padding-bottom: 0.25rem;
                margin: 0;
            }

            .content-cells3 {
                position: absolute;
                border-radius: 9999px;
                background-color: rgba(0, 0, 0, 1);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}