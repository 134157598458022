.speed-kino-results-container{
    border-width: 1px;
    border-color: rgba(221, 221, 221, 1);
    display: flex;
    flex-direction: column;
    width: 100%;

    .speed-kino-results-content{
        border-width: 1px;
        border-color: rgba(221, 221, 221, 1);
        padding: 0.25rem;
    }

    .strip-container{
        display: flex;
        overflow-x: scroll;

        .strip-content{
            border-right-width: 1px;
            border-color: rgba(221, 221, 221, 1);
            flex-shrink: 0;

            .strip-content1{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3.5rem;
                border-bottom-width: 1px;

                p{
                    margin: 0;
                }

                .strip-content2{
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    font-family: SpoqaHanSansNeoMedium;
                }
            }

            .strip-content3{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content4{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                }

            }

            .strip-content5{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content6{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                }
            }

            .strip-content7{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content8{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                }
            }

            .strip-content9{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content10{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content11{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content12{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content13{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content14{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content15{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content16{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content17{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content18{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content19{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content20{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

            .strip-content21{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-width: 1px;
                border-color: rgba(221, 221, 221, 1);

                .strip-content22{
                    position: relative;
                    border-radius: 9999px;
                    color: rgba(255, 255, 255, 1);
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.125rem;
                    padding-right: 1px;
                } 
            }

        }
    }
}