.상세보기 {
    width: 10.875rem;
    height: 10.875rem;
    background-color: rgb(130, 97, 64);
    border-radius: 1.1rem;
    box-shadow: rgb(0 0 0 / 60%) 0px 0.375rem 0px 0px;
    cursor: pointer;

    .상세보기-span {
        font-size: 2.7rem;
        line-height: 1.25;
        width: 10.875rem;
        margin-top: 0.2375rem;
        font-family: SpoqaHanSansNeoMedium;
        color: rgb(255, 223, 189);
        display: flex;
        justify-content: center;
        align-items: center;
        // text-shadow: rgb(0 0 0 / 50%) 0.3125rem 0.125rem 0.4375rem;
        // text-shadow: rgb(0 0 0) 0rem 0rem 0.6rem;
    }

    &:hover {
        filter: brightness(125%);
    }
}