.bet-history {

    .date12 {
        margin: 0.9rem 1.875rem;
        padding-top: 0.5rem;
    }


}

.LiveCasinoBetHistory {
    #scroll-wrapper1 {
        padding: 0px 0px 0rem 1.1rem !important;
        margin-top: 0.9rem;
        margin-bottom: -0.2rem;

        #container {
            .nav-button {
                width: 21.6rem;
                height: 11.18rem;
                margin-right: 0.2rem;

                .icon {
                    margin-top: 0;
                }

                &.active {
                    button {
                        background: url('../../assets/myPage/second-btns/active-bg.png') round !important;
                        background-repeat: round;
                        width: 20.9rem;
                        height: 13.6rem;
                        margin-right: 0.2rem;
                        padding-top: 0.5rem;
                        margin-left: 0.35rem;

                        .text {
                            color: #ccc2b6;
                        }
                    }
                }
            }
        }
    }
    .select-overlay {
        width: 100%;
    }
}