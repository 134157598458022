.WarningMessageClass {
    padding-left: 1.7rem;
    padding-top: 1.3rem;
    padding-bottom: 2.2rem;
    border-radius: 1.2rem;
    border: 0.1875rem solid rgb(89, 76, 73);
    margin-bottom: 0.625rem;
    background: rgb(65, 57, 55);
    letter-spacing: -0.07rem;
    font-family: 'SpoqaHanSansNeoMedium';

    .alert-icon-and-text {
        display: flex;
        align-items: center;

        img {
            width: 3.375rem;
            height: 3.0625rem;
            margin-right: 1rem;
            margin-top: 0.2rem;
            margin-left: 0.2rem;
        }

        span {
            font-size: 2.8125rem;
            line-height: 1.2;
            margin-top: 0.5rem;
            color: rgb(230, 84, 84);
        }
    }

    .alert-messages {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;

        .first-span {
            color: rgb(255, 223, 189);
            font-size: 2.625rem;
            line-height: 1.3;
            display: flex;

            span {
                margin-right: 0.6rem;
                margin-left: 0.2rem;
            }
        }

        .next-span {
            color: rgb(204, 194, 182);
            font-size: 2.625rem;
            margin-top: 1.6rem;
            line-height: 1.3;
            display: flex;

            span {
                margin-right: 0.6rem;
                margin-left: 0.2rem;
            }
        }
    }
}