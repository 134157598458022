.cardContent {

    .two-buttons-container {
        button {
            cursor: pointer;
            // box-shadow: 0 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.5);

            &:hover {
                filter: brightness(125%);
            }
        }
    }
}