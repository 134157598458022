.cscenter-page {
  #container-nav {
      padding: 0.1rem 1.6rem;

      #container {
          margin-left: 0 !important;
      }

      button {
          // width: 38.3rem;
          background-size: cover;
          // margin-right: -0.1rem;
      }
  }
}