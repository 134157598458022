.announcementTable-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;

    .announcementTable-1 {}

    .announcementTable {
        padding: 0.1875rem;
        background: linear-gradient(#4a4a4a, #1f1f1e);
        box-shadow: 0px 0px 1rem 0px rgb(0 0 0 / 60%);
        margin-top: 1.875rem;
        border-radius: 1rem;
        width: 100%;
        overflow: hidden;

        .announcementTable1 {
            display: flex;
            font-family: 'SpoqaHanSansNeoMedium';
            letter-spacing: -0.07rem;
            width: 100%;
            color: #eeeeee;
            border-bottom: 0.1875rem solid #252525;
            height: 11.8rem;

            &:first-child {
                border-top-right-radius: 1.1rem;
                border-top-left-radius: 1.1rem;
            }

            &:last-child {
                border-bottom-right-radius: 1.1rem;
                border-bottom-left-radius: 1.1rem;
            }

            .announcementTable2 {
                max-width: 56.25rem;
                padding: 1.6rem 2.2rem 1.6rem 0px;
                margin-left: 2.5625rem;
                display: flex;
                align-items: center;
                font-family: 'SpoqaHanSansNeoMedium';
                letter-spacing: -0.07rem;
                height: 100%;

                .announcementTable3 {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    .announcementTable4 {
                        font-family: 'SpoqaHanSansNeoMedium';
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        p {
                            margin: 0;
                            text-overflow: ellipsis;
                            max-width: 65rem;
                            font-size: 3rem;
                            letter-spacing: -0.07rem;
                            font-family: 'SpoqaHanSansNeoMedium';
                            color: #c8c8c8;
                            margin-top: 0.5rem;
                        }

                        .n-div {
                            background: #e9441d;
                            font-family: "RobotoMedium";
                            font-size: 2.4375rem;
                            padding: 0.132rem 0.735rem;
                            margin: 0;
                            margin-top: 0.5rem;
                            margin-left: 1rem;
                            border-radius: 0.77rem;
                            color: #fff;
                            // box-shadow: 1.5px 2.598px 1px 0px rgb(0 0 0 / 51%);
                            width: 3.1875rem;
                            height: 3.1875rem;
                        }

                    }

                    .announcementTable5 {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        font-family: 'SpoqaHanSansNeoMedium';
                        letter-spacing: -0.07rem;
                        text-align: center;

                        .announcementTable6 {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            color: #929292;
                            font-size: 2.625rem;

                            .announcementTable6-p {
                                display: flex;
                                align-items: center;
                                margin: 0;
                                color: #4c98ff;
                            }


                            .sep-cont {
                                height: 1.75rem;
                                width: 0.1875rem;
                                margin: auto 1rem auto 0.9rem;
                                background: #555555;
                            }

                            .time {
                                display: flex;
                                align-items: center;
                                font-family: 'SpoqaHanSansNeo';
                                font-size: 2.625rem;
                            }
                        }
                    }
                }
            }
        }

    }
}