.dropdown-container {
    position: relative;

    button {
        padding: 0;
        line-height: inherit;
        color: inherit;
        cursor: pointer;
    }

    .dropdown-content {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
        background: bottom;
    }

    .dropdown-content1 {
        position: fixed;
        top: 0px;
        justify-content: center;
        overflow: scroll;
        border-radius: 1.5rem;
    }

    .dropdown-content2 {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
    }
}