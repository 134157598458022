.first-row {
    margin-top: 1.15rem;
    margin-bottom: -1.125rem;
    display: flex;

    input {
        outline: none;
        background: #1a1a1a;
        border: 0;
        height: 7rem;
        width: 34.06rem;
        font-size: 2.624375rem;
        margin-left: 1.875rem;
        border: 0.1875rem solid #404040;
        border-radius: 0.5rem;
        color: #ccc2b6;
        font-family: 'SpoqaHanSansNeoMedium';
        letter-spacing: -0.07rem;
        padding: 0.35rem 0 0 1.65rem;
    }

    ::placeholder {
        color: #ccc2b6;
    }
}




.second-row {
    display: flex;

    input {
        outline: none;
        background: #1a1a1a;
        border: 0;
        padding: 0;
        height: 7rem;
        width: 34.06rem;
        font-size: 2.624375rem;
        // margin-left: 1.875rem;
        border: 0.1875rem solid #404040;
        border-radius: 0.5rem;
        color: #ccc2b6;
        font-family: 'RobotoMedium';
        padding-left: 1.65rem;
        letter-spacing: -0.1rem;
        padding-top: 0.15rem;
    }

    .date-picker {
        position: relative;

        img {
            position: absolute;
            top: 3.48rem;
            right: 2rem;
        }
    }

    .Signal {
        color: #8e8780;
        font-family: 'SpoqaHanSansNeoMedium';
        font-size: 2.624375rem;
        margin-top: 3.88rem;
        margin-left: 0.2rem;
        margin-right: -1.55rem;
    }

}

.buttons-container2 {
    // background: red;
    display: flex;
    margin: 0 1.9rem;
    margin-top: 0.7rem;
    gap: 0.92rem;
    color: #ffdfbd;
    font-family: 'SpoqaHanSansNeoMedium';
    font-size: 2.624375rem;
    padding-bottom: 1.8rem;

    .common-button12 {
        width: 13.25rem;
        height: 7.3125rem;
        background: linear-gradient(to top, #403e38, #72695d, #a59484);
        padding: 0.1875rem;
        border-radius: 0.6rem;
        box-shadow: 0 0.312rem 0.625rem 0 rgba(0, 0, 0, 0.3);
        cursor: pointer;

        &:hover {
            filter: brightness(125%);
        }

        .text-con {
            background: linear-gradient(to top, #4c4743, #615952, #776c62);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.4rem;

            span {
                margin-top: 0.2rem;
                letter-spacing: -0.07rem;
                text-shadow: rgb(0 0 0) 0rem 0rem 0.4375rem;
            }
        }
    }

    .date12 {
        padding-top: 0.7rem;
    }
}

@media (max-width: 600px) {
    .buttons-container2 {
        .common-button12 {
            padding: 1px;
        }

        // .common-button12>div {
        //     width: calc(100% - 1px);
        //     height: calc(100% - 1px);
        // }
    }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    left: -11% !important;
    top: 5rem !important;
    height: 46rem !important;
    width: 11.9875rem  !important;
    background: rgba(26, 26, 26) !important;
    color: rgba(204, 194, 182);
    font-size: 2.6rem;
    border: 0.1875rem solid rgba(64, 64, 64) !important;
    overflow-y: scroll;
    font-family: 'RobotoMedium';

    &::-webkit-scrollbar {
        display: block;
        height: 0.5rem !important;
        width: 1rem;
    }

    &::-webkit-scrollbar-thumb {
        background: #967c52;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        margin: 2px;
        background: #1e1e1e;
        border-radius: 10px;
    }
}


.react-datepicker__year-dropdown .react-datepicker__year-option:last-child {
display: none !important;
}

.react-datepicker__year-dropdown {
    .react-datepicker__year-option {
        &:nth-last-child(n):nth-last-child(n + 103) {
            display: none !important;
        }

    }
}
// react-datepicker__year-option


.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    height: 4.5rem;
    display: flex !important;
    justify-content: left;
    align-items: center;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    height: 4.5rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.1) !important;
}


.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--scroll {
    position: absolute !important;
    width: 17rem !important;
    left: 38% !important;
    top: 1rem !important;
    height: 3rem !important;
    margin: auto !important;
    // background: antiquewhite !important;
    // display: none;
    // opacity: 0;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    width: 100% !important;
    // background: red !important;
    height: 100% !important;
    margin: 0 !important;
    // opacity: 1;
    // display: none !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    color: #ffffff00;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-color: #ffffff00 !important
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    height: 100%;
    width: 100%;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    left: 1.5rem !important;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
    padding-left: 2rem;
}

.react-datepicker__year-option--selected {
    display: none;
}
