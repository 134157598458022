.question-cell {
    width: 73.875rem;
    z-index: 10;
    padding: 2.66rem 0 2.3rem;
    border-radius: 2.8125rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    background: #2e2e2e;
    // box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    padding-right: 10rem;

    .q-down-arrow {
        position: absolute;
        // top: 0;
        right: 0;
    }

    .q-icon {
        width: 5.5rem;
        height: 5.0625rem;
        margin-left: 2.45rem;
    }

    .q-type {
        font-family: "SpoqaHanSansNeoMedium";
        color: #ccc2b6;
        background: #494745;
        font-size: 2.4375rem;
        padding: 0.45rem 1.8rem;
        width: fit-content;
        margin-bottom: 0.7rem;
        margin-left: 0.25rem;
        border-radius: 2rem;
    }

    .q-text {
        margin: 0;
        font-size: 2.625rem;
        line-height: 1.2;
        text-align: start;
        margin-top: 1.1rem;
        color: #c8c8c8;
        font-family: "SpoqaHanSansNeoMedium";
        letter-spacing: -0.07rem;
        // color: black;
        margin-left: 0.25rem;
    }
}

.opened-cell {
    z-index: -1;
    position: relative;
    background-color: #252525;
    padding-left: 1.9rem;
    padding-right: 1.875rem;
    margin-bottom: 1.875rem;
    border-radius: 2.8125rem;
    margin-top: -6rem;
    padding-top: 6rem;
    color: #c8c8c8;
    font-family: 'SpoqaHanSansNeo';
    letter-spacing: -0.07rem;
    font-size: 2.625rem;
    // padding-bottom: 0.25rem;
    border: 0.1875rem solid #000;

    // &:last-child {
    //     margin-bottom: 1rem;
    // }
    p {
        margin: 0;
        letter-spacing: -0.07rem;
        // margin-bottom: 1.5rem;

        line-height: 1.95;

    }
}

.no1 {
    color: #4a90f0;
    margin-right: 0.55rem;

}