.popupcontrols-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupcontrols-content{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 1);
    --tw-bg-opacity: 0.8;
    top: 0px;
    left: 0px;
}

.popupcontrols-content1{
    overflow-y: scroll;
}