.tabs-list-container {
    display: flex;
    width: 100%;

    button {
        line-height: inherit;
        color: inherit;
        cursor: pointer;
    }

    .button-style {
        background-color: rgba(88, 186, 247, 1);
    }

    .button-style1 {
        background-color: rgba(255, 255, 255, 1);
    }

    .button-style2 {
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        border-radius: 0.5rem;

        .div-style1 {
            background-color: rgba(88, 186, 247, 1);
        }

        .div-style2 {
            background-color: rgba(255, 255, 255, 1);
        }

        .div-style {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            align-items: flex-end;
            background-color: rgba(255, 255, 255, 1);

            .div-style3 {
                margin-top: 3px;
                display: flex;
                height: 100%;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .div-style4 {
                    display: flex;
                    align-items: center;
                    margin-left: -1px;

                    .span-style {
                        margin-left: 2px;
                    }

                    .span-style1 {
                        font-family: SpoqaHanSansNeoBold;
                        letter-spacing: -0.025em;
                    }
                }

                .div-style5 {
                    display: flex;
                    align-items: center;
                    margin-top: -0.75rem;

                    .img-style {
                        object-fit: contain;
                        margin-top: 0.5rem;
                    }

                    .div-style6 {
                        display: flex;
                        align-items: center;
                        font-family: Swagger;
                        letter-spacing: -0.025em;
                    }
                }
            }
        }
    }
}