.select-all-buttons {
    margin: 4.05rem 0px 2.5rem;

    .flex-btn {
        display: flex;
        justify-content: center;
    }

    button {
        height: 7.3125rem;
        padding: 0.1875rem;
        font-size: 2.8125rem;
        margin-right: 0.75rem;
        width: 24.125rem;
        // background: linear-gradient(to top, rgb(77, 60, 10), rgb(156, 123, 74) 50%, rgb(231, 183, 134));
        border-radius: 1rem;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            filter: brightness(125%);
        }

        .div-text {
            width: 100%;
            height: 100%;
            // background: linear-gradient(to top, rgb(128, 96, 63), rgb(147, 110, 72) 50%, rgb(165, 123, 82));
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'SpoqaHanSansNeoMedium';

            span {
                font-size: 2.8125rem;
                margin-top: 0.2rem;
                letter-spacing: -0.07rem;
                text-shadow: rgb(0 0 0) 0rem 0rem 0.4375rem;
            }
        }
    }
}