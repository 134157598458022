.inbox-count {
    background: #e9441d;
    font-family: "RobotoMedium";
    font-size: 2.4375rem;
    padding: 0.3rem 1.3rem;
    margin: 0;
    margin-top: -0.3rem;
    // margin-left: 1.8rem !important;
    border-radius: 0.77rem;
    color: #fff;
    box-shadow: 0.09375rem 0.162375rem 0.0625rem 0 rgba(0, 0, 0, 0.51);
}