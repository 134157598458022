.mypage-points {
    margin-top: 0.1rem;

    #container-nav {
        padding: 0 0rem 0 1.4rem;
        margin-top: 0.1rem;
        button {
            width: 25rem;
        }
        .text {
            margin-top: -0.4rem;
            margin-left: -0.15rem;
        }

    }

    #container {
        margin-left: 0 !important;
    }


}

.points-apply {
    .WarningMessageClass {
        padding-bottom: 2.1rem;

        .alert-messages {

            .first-span {

                // color: #ffdfbd;
                span {
                    margin-right: 0.6rem;
                }
            }

            .second-span {
                margin-top: 1rem;

                .correct {
                    margin-left: 0.29rem;
                }
            }

            .third-span {
                margin-top: 0.7rem;

                .correct {
                    margin-right: 0.43rem;
                }
            }
        }
    }

    .form-section {
        margin-top: 0.375rem;
        // background: green;
        display: flex;
        height: 6.55rem;

        .lable-or-input {
            background: #191817;
            width: 45.1rem;
            // padding: 2.2rem 1.3rem;
            // letter-spacing: -0.25rem;
            border-radius: 0.5rem;

            label {
                font-size: 2.8125rem;
                color: rgb(200, 200, 200);
                font-family: 'SpoqaHanSansNeo';
                margin-bottom: 1.1875rem;
                display: inline-block;
                letter-spacing: -0.25rem;
            }

        }

    }

    .range-btn {
        margin-top: 1.9rem;
        margin-bottom: 0;
        padding: 0px 1rem;
        padding-bottom: 1.1rem;


        .f-div {
            margin-bottom: 0.9rem;
            display: flex;
            gap: 0.9rem;

            .div-range-border {
                width: 16.75rem;
                height: 7.3125rem;
                padding: 0.1875rem;
                background: linear-gradient(to top, #413e39, #85796c 50%, #a59584);
                border-radius: 0.5rem;
                box-shadow: 0 0.375rem 0.75rem 0 rgba(0, 0, 0, 0.6);

                button {
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to top, #4c4843, #665d55 50%, #776d62);
                    border-radius: 0.5rem;

                }

            }


            .s-div {
                margin-top: 0.4rem;
                margin-left: 0.125rem;
                @media screen and (min-width: 200px) and (max-width: 323px) {
                    margin-top: -0.4rem;
                }

                span {
                    font-family: 'SpoqaHanSansNeoMedium';
                    font-size: 2.625rem;
                    color: #ffdfbd;
                    padding: 2rem 0.2rem 0 0;
                    letter-spacing: -0.07rem;
                }
            }
        }
    }

    // .pop-up {
    //     .first-div {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         /* width: 37rem; */
    //         width: 36.4375rem;
    //         height: 7.3125rem;
    //         padding: 0.1875rem;
    //         margin-top: 3.75rem;
    //         margin-bottom: 17.75rem;
    //         background: black;

    //         button {
    //             width: 100%;
    //             height: 100%;
    //         }
    //     }
    // }
    .popup-page {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #323232;
        padding: 0.1875rem;
        border-radius: 3.6rem;
        left: 50%;
        transform: translate(-50%);
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 201;
        background-color: rgba(0, 0, 0, .802);

    }
}