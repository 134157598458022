.editDates {
    .date12 {
        .container {
            width: 34.06rem;
            height: 7rem;

            .select-overlay {

                width: 34.06rem;
                height: 7rem;
            }
        }

    }
}