.powrball-history-container{
    width: 100%;

    .powrball-history-content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(250, 250, 250, 1);
        font-family: SpoqaHanSansNeoMedium;
        font-size: 14px;
        letter-spacing: -0.025em;
        color: rgba(69, 69, 69, 1);
        height: 3.5rem;
        border-bottom-width: 1px;
        border-color: rgba(221, 221, 221, 1);

        .powrball-history-content1{
            display: flex;
            justify-content: center;
            margin-left: 1.75rem;

        }

        .powrball-history-content2{
            display: flex;
            justify-content: center;
        }
    }


    .inbox-list-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: SpoqaHanSansNeo;
        font-size: 14px;
        letter-spacing: -0.025em;
        color: rgba(69, 69, 69, 1);
        border-bottom-width: 1px;
        border-color: rgba(221, 221, 221, 1);

        .inbox-list-content{
            display: flex;
            justify-content: center;
            margin-left: 1.75rem; 
        }

        .inbox-list-content1{
            display: flex;
            justify-content: center;
        }

        .inbox-list-content2{
            display: flex;
            align-items: center;
            justify-content: center;

            .inbox-list-content3{
                height: 2.75rem;
                width: 2.75rem;
                border-radius: 9999px;
                background-color: rgba(0, 0, 0, 1);
                color: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: SpoqaHanSansNeoMedium;
                letter-spacing: -0.025em;
                padding-top: 0.125rem;

                .inbox-list-content4{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: -0.375rem;
                    margin-top: -0.125rem;
                }
            }
        }

        .inbox-list-content5{
            display: flex;
            justify-content: center;
        }

        .inbox-list-content6{
            display: flex;
            justify-content: flex-end;
        }
    }
}