.LiveCasinoHistoryDetailPopup {
    position: fixed;
    top: 0px;
    right: 0px;
    // left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 201;
    margin: auto;
    overflow-y: scroll;


    .거래-상세-내역-popup {
        height: 9.2rem;
        font-size: 3.75rem;
        background: #272726;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        border-top-left-radius: 2.7rem;
        border-top-right-radius: 2.7rem;
        // border-top-left-radius: 3rem;
        // border-top-right-radius: 3rem;

        label {
            color: #ad9e8c;
            letter-spacing: -0.1rem;
            font-size: 3.75rem;
            font-family: 'SpoqaHanSansNeoBold';
            margin-left: -0.35rem;
            margin-top: 0.1rem;
        }

        button {
            position: absolute;
            right: 0;
            background: bottom;
            margin-right: 4.2rem;

            img {
                width: 3.1875rem;
                height: 3.1875rem;
            }
        }
    }

    .WINlouie3-div2 {
        height: 5.1875rem;
        font-size: 2.8125rem;
        padding-top: 0.875rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #272726;
        margin-top: 2.6rem;
        border-radius: 3rem;
        width: 41.4rem;
        margin-bottom: 3.6rem;
    }
}