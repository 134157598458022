.speedgame-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .speedgame-content{
        width: 100%;
        display: flex;
        flex-direction: column;

        .speedgame-content1{
            display: flex;
            flex-direction: column;

            .speedgame-content2{
                display: flex;

                .speedgame-content3{
                    display: flex;
                }
            }
        }
    }
}

.single-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .single-card-content{
        display: flex;
        align-items: center;

        .single-card-content1{
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            
            .single-card-content2{
                display: flex;
                justify-content: space-around;
                width: 100%;
                align-items: center;

                .single-card-content3{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    p{
                        margin: 0;
                    }

                    .single-card-content4{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        div:hover{
                            opacity: 0.75;
                        }

                        p{
                            margin: 0;
                        }

                        .single-card-content5{
                            object-fit: contain;
                        }

                        .single-card-content6{
                            z-index: 20;
                            font-family: swagger;
                            color: rgba(255, 255, 255, 1);
                            position: absolute;
                        }
                    }

                    .single-card-content7{
                        font-family: RobotoRegular;
                        letter-spacing: -0.025em;
                        color: #fff;
                        margin-top: -0.55rem;
                    }
                }

                .single-card-content8{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .single-card-content9{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        div:hover{
                            opacity: 0.75;
                        }

                        p{
                            margin: 0;
                        }

                        .single-card-content10{
                            object-fit: contain;
                        }

                        .single-card-content11{
                            z-index: 20;
                            font-family: swagger;
                            color: rgba(255, 255, 255, 1);
                            position: absolute;
                        } 
                    }

                    .single-card-content12{
                        font-family: RobotoRegular;
                        letter-spacing: -0.025em;
                        color: #fff;
                        margin-top: -0.55rem;
                    }
                }
            }
        }
    }
}

.panel-title-container{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 1px;

    .panel-title-content{
        flex-shrink: 0;
        border-radius: 1rem;
        border-color: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.025em;
        color: rgba(255, 255, 255, 1);

        p{
            margin: 0;
        }

        .panel-title-content1{
            font-family: SpoqaHanSansNeoMedium;
            padding-bottom: 1px;
        }
    }

    .panel-title-content2{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.25rem;

        .panel-title-content3{
            letter-spacing: -0.025em;
            color: rgba(88, 88, 88, 1);
            font-family: SpoqaHanSansNeoMedium;
            display: flex;
            align-items: center;
            margin-top: 0.75rem;
        }
    }
}

.threecard-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .threecard-content{
        display: flex;
        align-items: center;
        border-bottom-width: 1px;
        border-color: rgba(207, 148, 148, 1);

        .threecard-content1{
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            .threecard-content2{
                display: flex;
                justify-content: space-around;
                width: 100%;
                align-items: center;
                // margin-left: 5rem;

                .threecard-content3{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // margin-left: 1.25rem;

                    .threecard-content4{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        div:hover{
                            opacity: 0.75;
                        }
    
                        p{
                            margin: 0;
                        }
    
                        .threecard-content5{
                            object-fit: contain;
                        }
    
                        .threecard-content6{
                            z-index: 20;
                            font-family: swagger;
                            color: rgba(255, 255, 255, 1);
                            position: absolute;
                        }
                    }
    
                    .threecard-content7{
                        font-family: RobotoRegular;
                        letter-spacing: -0.025em;
                        color: #fff;
                        // margin-top: -0.85rem;
                    }
                }

                .threecard-content8{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    

                    .threecard-content9{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        div:hover{
                            opacity: 0.75;
                        }
    
                        p{
                            margin: 0;
                        }
    
                        .threecard-content10{
                            object-fit: contain;
                        }
    
                        .threecard-content11{
                            z-index: 20;
                            font-family: 'Swagger';
                            color: rgba(255, 255, 255, 1);
                            position: absolute;
                        }
                    }
    
                    .threecard-content12{
                        font-family: 'RobotoRegular';
                        letter-spacing: -0.025em;
                        color: #fff;
                        margin-top: -0.85rem;
                        margin-left: -0.2rem;
                    }
                }


            }
        }
    }
}
