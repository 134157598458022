.container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header{
    width: 100%;
    z-index: 30;
    // display: flex;
    flex-direction: column;
    align-items: center;
}

.contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.content_1{
    width: 100%;
    display: flex;
    justify-content: center;
}