// button{
//     padding: 0;
//     line-height: inherit;
//     color: inherit;
//     background-color: transparent;
//     background-image: none;
// }
.speedkino-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.5rem;

    .speedkino-content{
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(93, 121, 156, 1);

        .speedkino-content1{
            font-family: SpoqaHanSansNeoBold;
            letter-spacing: -0.025em;
            color: rgba(255, 255, 255, 1);
        }

        .speedkino-content2{
            position: absolute;
            right: 0px;
            z-index: 20;
            cursor: pointer;
        }
    }

    .speedkino-content3{
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
    }
}