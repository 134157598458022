// *{
// box-sizing: border-box;
// border-width: 0;
// border-style: solid;

//     p{
//         margin: 0;
//     }
// }
.minigamesrightpanel-container {
    z-index: 50;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .minigamesrightpanel-content {
        display: flex;

        .minigamesrightpanel-content1 {
            display: flex;
            align-items: center;
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(255, 255, 255, 1);
        }

        .minigamesrightpanel-content2 {
            display: flex;
            align-items: center;
            font-family: SpoqaHanSansNeoBold;
            letter-spacing: -0.025em;

            .minigamesrightpanel-content3 {
                object-fit: contain;
                margin-bottom: 0.25rem;
            }
        }
    }

    .minigamesrightpanel-content4 {
        display: flex;

        .minigamesrightpanel-content5 {
            cursor: pointer;
            object-fit: contain;
        }

        .buttonminigamesrightpanel {
            background-color: transparent;
            background-image: none;

            .minigamesrightpanel-content6 {
                cursor: pointer;
                object-fit: contain;
            }
        }
    }
}

.rightpanel-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.75rem;

    .rightpanel-content {
        // border-bottom-width: 1px;
        // border-color: rgba(221, 221, 221, 1);
        // background-color: rgba(251, 251, 251, 1);

        .rightpanel-content1 {
            position: relative;
            display: flex;
            width: 100%;
            border-radius: 0.125rem;

            .rightpanel-content2 {
                position: absolute;
                // z-index: 10;
                object-fit: contain;
            }

            .rightpanel-content3 {
                z-index: 20;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;

                .rightpanel-content4 {
                    object-fit: contain;
                    margin-right: 0.25rem;
                }

                .rightpanel-content5 {
                    font-family: SpoqaHanSansNeoBold;
                    letter-spacing: -0.025em;
                }
            }

            .rightpanel-content6 {
                z-index: 20;
                display: flex;
                height: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // margin-top: 0.75rem;

                .rightpanel-content7 {
                    display: flex;
                    align-items: center;
                    font-family: SpoqaHanSansNeoMedium;
                    letter-spacing: -0.025em;
                }

                .rightpanel-content8 {
                    display: flex;
                    align-items: center;
                    font-family: SpoqaHanSansNeoBold;
                    letter-spacing: -0.025em;
                }

                .rightpanel-content9 {
                    display: flex;
                    align-items: center;
                    font-family: SpoqaHanSansNeoBold;
                    letter-spacing: -0.025em;
                }
            }

            .rightpanel-content10 {
                z-index: 20;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: flex-end;

                .rightpanel-content11 {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    height: 100%;

                    .rightpanel-content12 {
                        padding-top: 0.75rem;
                    }

                    .rightpanel-content13 {
                        position: absolute;
                        object-fit: contain;
                    }

                    .rightpanel-content14 {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        // margin-top: 1.25rem;
                        // margin-right: 0.5rem;

                        .rightpanel-content15 {
                            z-index: 20;
                            font-family: swagger;
                            color: rgba(255, 255, 255, 1);
                            font-size: 20px;
                            margin-top: 0.25rem;

                            .rightpanel-content16 {
                                z-index: 20;
                                font-family: SpoqaHanSansNeo;
                                color: rgba(255, 255, 255, 1);
                                letter-spacing: -0.025em;
                            }
                        }
                    }

                    .rightpanel-content17 {
                        left: 1rem;
                        padding-right: 0.25rem;
                    }

                    .rightpanel-content18 {
                        right: 1rem;
                        padding-right: 0.125rem;
                    }

                    .rightpanel-content19 {
                        position: absolute;
                        width: 5.5rem;
                        height: 5.5rem;
                        border-radius: 9999px;
                        background-color: rgba(0, 0, 0, 1);
                        z-index: 20;
                        top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .rightpanel-content20 {
                            color: rgba(255, 255, 255, 1);
                            font-size: 0.75rem;
                            letter-spacing: -0.025em;
                            font-family: SpoqaHanSansNeoMedium;
                        }
                    }
                }
            }
        }
    }

    .rightpanel-content21 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: rgba(221, 221, 221, 1);
        padding-top: 0.25rem;


        .rightpanel-content22 {
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(69, 69, 69, 1);
        }

        .rightpanel-content23 {
            font-family: RobotoBold;
            letter-spacing: -0.025em;
            color: rgba(0, 86, 166, 1);
        }
    }

    .minirightpanel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: rgba(221, 221, 221, 1);
        padding-top: 0.5rem;


        .minirightpanel1 {
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(69, 69, 69, 1);
        }

        .minirightpanel2 {
            font-family: RobotoMedium;
            letter-spacing: -0.025em;
        }
    }

    .minirightpanel3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: rgba(221, 221, 221, 1);

        .minirightpanel4 {
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(69, 69, 69, 1);
        }

        .minirightpanel5 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-radius: 0.5rem;
            border-color: rgba(221, 221, 221, 1);
            padding-top: 0.25rem;
            padding-right: 2rem;
            text-align: right;
            font-family: RobotoMedium;
            letter-spacing: -0.025em;
        }
    }

    .minirightpanel6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: rgba(221, 221, 221, 1);
        padding-top: 0.25rem;

        .minirightpanel7 {
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(69, 69, 69, 1);
        }

        .minirightpanel8 {
            font-family: RobotoMedium;
            letter-spacing: -0.025em;
            color: rgba(0, 86, 166, 1);
        }

    }

    .betamountbutton-container {
        width: 100%;

        .betamountbutton-content {
            display: flex;
            flex-direction: column;

            .betamountbutton-content1 {
                display: flex;
            }

            .betamountbutton-content2 {
                display: flex;
            }

            .betamountbutton-content3 {
                display: flex;

                .betamountbutton-content4 {
                    flex-shrink: 0;
                }

                .betamountbutton-content5 {
                    flex-shrink: 0;
                }

                .betamountbutton-content6 {
                    flex-shrink: 0;
                }
            }
        }

        .button-style-content {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            background-color: rgba(32, 104, 178, 1);

            button:hover {
                opacity: 0.75;
            }

            .button-style-content1 {
                display: flex;
                height: 100%;
                width: 100%;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                border-width: 1px;
                border-color: rgba(57, 117, 174, 1);
                background-image: linear-gradient(to bottom, #125a9e, #0b447a);

                .button-style-content2 {
                    object-fit: contain;
                }

                .button-style-content3 {
                    font-family: SpoqaHanSansNeoMedium;
                    letter-spacing: -0.025em;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    .div-style-content {
        position: absolute;
        width: 74.7rem;
        height: 98.8%;
        background-color: rgba(0, 0, 0, 1);
        top: 1rem;
        left: 1.3rem;
        // --tw-bg-opacity: 0.7;
        opacity: 0.7;
        // z-index: 100%;
        display: flex;
        justify-content: center;
        border-radius: 0.8rem;


        .div-style-content1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: -6rem;

            .div-style-content2 {
                object-fit: contain;
            }

            .div-style-content3 {
                color: rgba(255, 255, 255, 1);
                font-family: 'SpoqaHanSansNeoMedium';
                font-size: 1.125rem;
                letter-spacing: -0.025em;
                margin-top: 4rem;
            }
        }
    }
}

.popup-container {
    display: flex;
    justify-content: center;
}

.bet-button {
    display: flex;
    flex-shrink: 0;
    border-width: 1px;
    border: 0.1875rem;
    border-style: solid;

    .bet-div {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .bet-span {
            font-family: RobotoMedium;
            letter-spacing: -0.025em;
            color: rgba(88, 88, 88, 1);
        }
    }
}

.betfixed-button {
    display: flex;
    flex-shrink: 0;
    border-width: 1px;

    button:hover {
        opacity: 0.75;
    }

    .betfixed-div {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .betfixed-span {
            font-family: SpoqaHanSansNeoMedium;
            letter-spacing: -0.025em;
            color: rgba(255, 255, 255, 1);
        }
    }
}

.gray-button {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);

    .gray-button-div {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: rgba(199, 199, 199, 1);

        .gray-button-div1 {
            display: flex;
            // margin-top: 3px;
            height: 100%;
            width: 100%;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;

            .gray-button-div2 {
                display: flex;
                align-items: center;
                font-family: SpoqaHanSansNeoMedium;
                color: rgba(88, 88, 88, 1);
            }
        }
    }
}