.coupon-usage {
    margin-top: 0.1rem;


    #container-nav {
        padding: 0 0rem 0 1.4rem;
        margin-top: 0.1rem;
        button {
            width: 25rem;
        }
        .text {
            margin-top: -0.4rem;
            margin-left: -0.15rem;
        }

    }

    #container {
        margin-left: 0 !important;
    }
}

.coupon-all {
    .button-class-div {
        margin: auto;
        margin-top: 3.3rem;
        margin-bottom: 3.75rem;
        width: fit-content;
        padding: 0.1875rem;
        background: linear-gradient(to top, #445896, #699ec3 50%, #87d8e7);
        border-radius: 1rem;
        width: 36.4375rem;
        height: 7.3125rem;

        .button-class {
            border: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            // border:  solid;
            background: red;
            // padding: 0.1875rem;
            border-radius: 1rem;
            background: linear-gradient(to top, #3a6287, #4778ac 50%, #518bcb);
            box-shadow: 0 0.375rem 0.75rem 0 rgba(0, 0, 0, 0.6);

            span {
                font-size: 2.80625rem;
                color: #ffffff;
                font-family: "SpoqaHanSansNeoMedium";
                margin-right: -0.19rem;
                width: fit-content;
                letter-spacing: -0.06rem;
            }
        }
    }

    .popup-page {
        overflow: scroll;
        background: rgba(0, 0, 0, 0.8);
        // background: red;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%);

        margin: auto;
        display: flex;
        justify-content: center;
    }

    .button-class-div {
        margin: auto;
        margin-top: 3.7rem;
        margin-bottom: 3.75rem;
        width: fit-content;
        padding: 0.1875rem;
        background: linear-gradient(to top, #445896, #699ec3 50%, #87d8e7);
        border-radius: 1rem;
        width: 36.4375rem;
        height: 7.3125rem;

        .button-class {
            border: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            // border:  solid;
            background: red;
            // padding: 0.1875rem;
            border-radius: 1rem;
            background: linear-gradient(to top, #3a6287, #4778ac 50%, #518bcb);
            box-shadow: 0 0.375rem 0.75rem 0 rgba(0, 0, 0, 0.6);

            span {
                font-size: 2.80625rem;
                color: #d6f3ff;
                font-family: "SpoqaHanSansNeoMedium";
                margin-right: -0.19rem;
                width: fit-content;
                letter-spacing: -0.06rem;
            }
        }
    }


}
