.DistributorPage {
    .date12 {
        padding-top: 0rem !important;

        .first-row input {
            margin-top: -4.6rem;
            margin-left: 0.1rem !important;
            width: 41.66rem !important;
            height: 6.9rem !important;
            padding-left: 1.9rem !important;


        }

        .first-row {
            .common-button {
                margin-bottom: 1.3rem !important;
            }
        }

        .withBlackButton {
            margin-bottom: 1.3rem !important;
        }
    }
}