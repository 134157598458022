.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type='checkbox'] {
  appearance: none;
  background-color: #1e1e1e;
  margin: 0;
  font: inherit;
  color: #927c55;
  width: 3rem;
  height: 3rem;
  border: 0.2rem solid #41403f;
  border-radius: 0.9rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='radio'] {
  appearance: none;
  background-color: #1e1e1e;
  margin: 0;
  font: inherit;
  color: #927c55;
  width: 3rem;
  height: 3rem;
  border: 0.2rem solid #41403f;
  border-radius: 0.9rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='radio'].radio-input {
  border-radius: 50%;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type='checkbox']::before {
  content: '';
  width: 1.875rem;
  height: 1.875rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #927c55;
  background-color: #927c55;
  border-radius: 0.6rem;
}

input[type='radio']::before {
  content: '';
  width: 1.875rem;
  height: 1.875rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #927c55;
  background-color: #927c55;
  border-radius: 0.6rem;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='radio']:checked::before {
  transform: scale(1);
}

input[type='radio'].radio-input:checked::before {
  border-radius: 50%;
}
