/* width */
::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  margin: 2px;
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
