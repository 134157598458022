.Jackpot {
    position: relative;

    .jackpot-container {
        border-radius: 1rem;
        margin: 1.1rem 1.3rem;
        overflow: hidden;

        width: 75rem;

        img {
            margin-top: -16.79rem;
            margin-left: -1.48rem;
            width: 32.4375rem;
            height: 38.25rem;
        }


    }

    .jackpot-range {
        position: absolute;
        // background: url('../../assets/jackpot-range.png');
        background-repeat: round;
        width: 43.75rem;
        height: 8.8125rem;
        top: 2.431rem;
        right: 4.45rem;
        z-index: 10;
        text-align: center;

        p {
            color: #b89953;
            font-family: "SpoqaHanSansNeoBold";
            font-size: 4.5rem;
            margin: 1.57rem 0 0 2rem;
            letter-spacing: 0.23rem;
            background: -webkit-linear-gradient(#ffbe37, #fef1af 50%, #ffbe37);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}