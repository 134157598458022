.container_1 {
    position: fixed;
    bottom: 0px;
    z-index: 200;
    width: 100%;
    background-color: rgb(0 0 0 / 70%);
    // background: url('../../2.png');
}

.container_2 {
    position: fixed;
    bottom: 0px;
    z-index: 50;
    width: 100%;

}

.container_3 {
    display: flex;
    height: 100%;
    align-items: center;
}

.container_4 {
    opacity: 0;
}

.container_5 {
    opacity: 1;
}

.container_6 {
    position: absolute;
    bottom: 0px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    background: url('../../assets/BetCart/bg.png');
    background-repeat: round;
    letter-spacing: -0.07rem;
    box-shadow: 0px -6px 36px 0px rgba(0, 0, 0, 0.75);
}

.container_7 {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    padding-top: 0.25rem;
    font-family: RobotoMedium;
    line-height: 2.5rem;
    font-size: 2.25rem;
    color: rgba(255, 255, 255, 1);
}

.container_8 {
    margin: 0;
    margin-top: 0.3rem;
    flex-shrink: 0;
    font-family: 'SpoqaHanSansNeoMedium';
    line-height: 1;
    font-size: 3rem;
    color: #ccc2b6;
    margin-left: 0.1rem;
}

.container_9 {
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
    background-color: transparent;
    background-image: none;
}

.container_10 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: SpoqaHanSansNeoMedium;
    line-height: 1;
    font-size: 3rem;
    color: #ccc2b6;
}

.container_11 {
    margin-right: 2.8rem;
    // margin-top: 0.5rem;
    flex-shrink: 0;
}

.container_12 {
    color: #e65454;
}

.link-content1 {
    // margin-bottom: 0.5rem;
    margin-top: 0.7rem;
    margin-right: 1.1rem;
    height: 6rem;
    flex-shrink: 0;
    cursor: pointer;
    object-fit: contain;
}

.link-content2 {
    // margin-bottom: 0.5rem;
    // margin-right: 1.5rem;
    margin-top: 0.7rem;
    height: 6rem;
    flex-shrink: 0;
    cursor: pointer;
    object-fit: contain;
}

.expand-content-1 {
    z-index: 50;
    opacity: 0;
}

.expand-content-2 {
    width: 99.4%;
    // background-color: rgba(255, 255, 255, 1);
    background: linear-gradient(to top, #1f1f1e, #4a4a4a);
    // background: green;
}