.cart-container{
    position: fixed;
    bottom: 0px;
    z-index: 50;
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    --tw-bg-opacity: 0.6;
    //opacity: 0.6;

    img{
        max-width: 100%;
        height: auto;
    }

    p{
        margin: 0;
    }

    .cart-content{
        position: fixed;
        bottom: 0px;
        z-index: 30;
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        .cart-content1{
            opacity: 0;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
        }

        .cart-content2{
            opacity: 1;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
        }

        .cart-content3{
            background-color: rgba(255, 255, 255, 1);
            position: absolute;
            bottom: 0px;
            display: flex;
            width: 100%;
            align-items: center;
            height: 100%;
            flex-shrink: 0;
            justify-content: space-between;

            .cart-content4{
                display: flex;
                align-items: center;

                .cart-content5{
                    object-fit: contain;
                }

                .cart-content6{
                    display: flex;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;
                    border-radius: 9999px;
                    background-color: rgba(237, 47, 89, 1);
                    padding-top: 0.25rem;
                    font-family: RobotoMedium;
                    font-size: 2.25rem;
                    line-height: 2.5rem;
                    color: rgba(255, 255, 255, 1);
                }

                .cart-content7{
                    margin-top: 0.5rem;
                    flex-shrink: 0;
                    font-family: SpoqaHanSansNeoMedium;
                    font-size: 3rem;
                    line-height: 1;
                    color: rgba(114, 114, 114, 1);
                }
            }

            .cart-content8{
                display: flex;
                flex-direction: column;
                flex-shrink: 0;

                .cart-content9{
                    object-fit: contain;
                    background-color: white;
                }
            }

            .cart-content10{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-family: SpoqaHanSansNeoMedium;
                font-size: 3rem;
                line-height: 1;
                color: rgba(114, 114, 114, 1);

                .cart-content11{
                    margin-right: 2rem;
                    margin-top: 0.5rem;
                    flex-shrink: 0;

                    .cart-content12{
                        color: rgba(213, 46, 46, 1);
                    }
                }

                .cart-content13{
                    margin-bottom: 0.5rem;
                    margin-right: -1rem;
                    height: 6rem;
                    flex-shrink: 0;
                    cursor: pointer;
                    object-fit: contain;
                }

                .cart-content14{
                    margin-bottom: 0.5rem;
                    margin-right: -1.5rem;
                    height: 6rem;
                    flex-shrink: 0;
                    cursor: pointer;
                    object-fit: contain;
                }
            }
        }

        .cart-content15{
            z-index: 50;
            display: flex;
            border-top-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;
            --tw-bg-opacity: 0;

            .cart-content16{
                z-index: 50;
                width: 100%;
                background-color: rgba(255, 255, 255, 1);
            }
        }
    }
}