.dropdown-mypage {
    .popup-country {
        width: 23.8rem !important;
        height: 16.2rem !important;

        .button-height {
            height: 7.8rem !important;
            display: flex;
            // justify-content: space-evenly;
            /* align-items: center; */
            padding-top: 2rem !important;
            padding-left: 2.7rem !important;

            label {
                margin-left: 4.4rem !important;
            }
        }
    }
}