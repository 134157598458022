.container {
    // overflow-x: scroll ;
    flex-direction: column;
    display: flex;
    width: 100%;


    .header-style {
        // align-items: center;
        flex-direction: column;
        width: 100%;
        display: flex;
        z-index: 50;
    }

    .sport-container {

        // position: relative;
        // z-index: 20;
        #container {
            button {
                width: 15.6rem !important;
                height: 15.7rem !important;

            }

            .hasNumber {
                margin-bottom: 1.7rem !important;
                margin-left: 5.4rem !important;

                p {
                    color: #ffffff !important;
                    margin: -0.05rem 0px 0px -0.2rem !important;
                }
            }

            .paragraph {
                margin: -0.2rem 0px 0.4rem 0.6rem !important;

            }

            .changeMargin {
                margin-top: 0.7rem !important;
                height: auto !important;
            }
        }

    }

    .content {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100%;
        display: flex;
        margin-top: 0.9rem;

        .content-content {
            width: 100%;
            display: flex;
            position: relative;

            .betcombpanel-container {
                width: 100%;
            }
        }
    }

}

.bet-history-withMargin {
    img {
        width: 6.0625rem !important;
    }
}