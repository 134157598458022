.container {
    width: 100%;

    p {
        margin: 0;
    }

    .divcontent {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .divcontent1 {
            display: flex;
            width: 100%;
            justify-content: center;

            .divcontent2 {
                display: flex;
                align-items: center;
                font-family: SpoqaHanSansNeoMedium;
                letter-spacing: -0.025em;
                color: rgba(255, 255, 255, 1);
            }

            .divcontent3 {
                display: flex;
                align-items: center;
                font-family: SpoqaHanSansNeoBold;
                letter-spacing: -0.025em;

                .imgcontent {
                    object-fit: contain;
                    padding-bottom: 1px;
                }
            }
        }

        .imgcontent1 {
            cursor: pointer;
        }
    }

    .container1 {
        width: 100%;

        .divcontent4 {
            position: relative;
            z-index: 20;
            display: flex;
            width: 100%;
            overflow: hidden;
        }
    }
}