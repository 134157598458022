.button-card {
    // /* background: red; */
    // width: 73.85rem;
    // /* width: 74rem; */
    // margin-left: 1.9rem;
    // padding: 0.1875rem;
    // border: 0;
    // border-radius: 1.1rem;
    // height: auto;
    // background: linear-gradient(to top, #1f1f1e, #303030 50%, #4a4a4a);

    .div-2 {
        // background: black;
        border-radius: 1.1rem;
        width: 100%;
        height: fit-content;
        /* background: red; */
        width: 73.85rem;
        /* width: 74rem; */
        margin-left: 1.9rem;
        padding: 0.1875rem;
        border: 0;
        border-radius: 1.1rem;
        height: auto;
        background: linear-gradient(to top, #1f1f1e, #303030 50%, #4a4a4a);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.6);

        .div-3 {
            // background: green;
            border-radius: 1.1rem;
            width: 100%;

            button {
                border: 0;
                padding: 0;
                width: 100%;
                background: bottom;
                border-radius: 1.1rem;
                height: 11.81rem;

                &:first-child {
                    .div-button {
                        border-top-right-radius: 1.1rem;
                        border-top-left-radius: 1.1rem;
                    }
                }

                &:last-child {
                    .div-button {
                        border-bottom: 0;
                        border-bottom-right-radius: 1.1rem;
                        border-bottom-left-radius: 1.1rem;

                    }
                }

                .div-button {
                    // border-radius: 1.3rem;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // height: 11.75rem;   
                    height: 100%;
                    padding-left: 2.3rem;
                    padding-top: 0.3rem;
                    padding-top: 1rem;
                    border-bottom: 0.1875rem solid #252525;
                    position: relative;

                    // &:first-child {
                    //     border-top-right-radius: 1.1rem;
                    //     border-top-left-radius: 1.1rem;
                    // }



                    .speaker {

                        // margin-top: -0.7rem;
                        img {

                            width: 3.3125rem;
                            // height: 3.3125rem;
                            // margin-right: 2.3rem;
                        }
                    }

                    .card-content {
                        // background: red;
                        // height: 9rem;
                        margin-bottom: 1rem;

                        &:first-child {
                            border-top-right-radius: 1.3rem;
                            border-top-left-radius: 1.3rem;
                        }

                        // &:last-child {
                        //     // border-bottom: 0;
                        //     background: #e9441d;
                        // }

                        .type {
                            display: flex;
                            margin-left: 2.3rem;
                            height: 3rem;
                            margin-top: 1.06rem;

                            .aaa {
                                margin-top: -1.1rem;
                                font-size: 2.4375rem;
                                padding: 0.6rem 1.7rem 0.4rem;
                                // background: blue;
                                border-radius: 2rem;
                                color: #fff;
                                font-family: "SpoqaHanSansNeoMedium";

                                span {
                                    padding-top: 0.1rem;

                                }
                            }

                            .text {
                                margin: 0;
                                // background-color: green;
                                margin-left: 0.89rem;
                                font-size: 2.999375rem;
                                margin-top: -0.81rem;
                                /* letter-spacing: -0.2rem; */
                                font-family: "SpoqaHanSansNeoMedium";
                                letter-spacing: -0.07rem;
                                white-space: nowrap;
                                max-width: 47.5rem;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                &.yellow-bg {
                                    // background: yellow;
                                    color: #d2b28f;
                                    // margin-left: 0.6rem;
                                    margin-top: -0.9rem;
                                }

                                &.blue-bg {
                                    // background: blue;
                                    color: #65add8;
                                }

                                &.black-bg {
                                    // background: black;
                                    color: #c8c8c8;
                                    margin-left: -2.2rem;
                                    margin-top: -0.9rem;
                                }
                            }


                            .N {
                                background: #e9441d;
                                font-family: "RobotoMedium";
                                font-size: 2.4375rem;
                                padding: 0.132rem 0.735rem;
                                margin: 0;
                                margin-top: -0.7rem;
                                margin-left: 1rem;
                                border-radius: 0.77rem;
                                color: #fff;
                            }
                        }

                        .logo-div {
                            // background: black;
                            width: fit-content;
                            margin-left: 2.3rem;
                            margin-top: 0.5rem;

                            .card-with-rank {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 3rem;
                                margin-left: -2rem;
                                color: #929292;
                                font-family: 'SpoqaHanSansNeoMedium';
                                font-size: 2.5875rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.button-class-div {
    margin: auto;
    margin-top: 3.7rem;
    margin-bottom: 3.75rem;
    width: fit-content;
    padding: 0.1875rem;
    background: linear-gradient(to top, #445896, #699ec3 50%, #87d8e7);
    border-radius: 1rem;
    width: 36.4375rem;
    height: 7.3125rem;

    .button-class {
        border: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        // border:  solid;
        background: red;
        // padding: 0.1875rem;
        border-radius: 1rem;
        background: linear-gradient(to top, #3a6287, #4778ac 50%, #518bcb);
        box-shadow: 0 0.375rem 0.75rem 0 rgba(0, 0, 0, 0.6);

        span {
            font-size: 2.80625rem;
            color: #ffffff;
            font-family: "SpoqaHanSansNeoMedium";
            margin-right: -0.19rem;
            width: fit-content;
            letter-spacing: -0.06rem;
        }
    }
}