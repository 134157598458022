.roundresultpopup-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.5rem;

    .roundresultpopup-content{
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background-color: #272726;

        .roundresultpopup-content1{
            font-family: SpoqaHanSansNeoBold;
            letter-spacing: -0.025em;
            color: rgba(255, 255, 255, 1);
        }

        .roundresultpopup-content2{
            position: absolute;
            right: 0px;
            z-index: 20;
            cursor: pointer;
            background-color: transparent;
            background-image: none;
        }
    }

    .roundresultpopup-content3{
        width: 100%;
        height: 100%;
        // background-color: rgba(255, 255, 255, 1);
    }
}