.search-container {
    border: 0;
    width: 95.1%;
    margin-left: 1.9rem;
    // margin: auto;
    padding: 0 1.875rem;
    background: #2e2e2e;
    border-radius: 1.1rem;
    height: 10.4375rem;
    margin-bottom: 13.8rem;
    margin-top: -0.1rem;
    display: inline-block;

    .content {
        height: 100%;

        .container {
            width: 100%;
            height: 6.75rem;

            .select-overlay {
                width: 100%;
                height: 100%;
                // opacity: 1;

            }

            .sort-by-div {
                padding-bottom: 1rem;
            }
        }
    }


    .options-div {
        // background: blue;
        position: relative;

        .left-div-options {
            background: #1a1a1a;
            margin-left: -1.6rem;
            margin-top: 0.15rem;
            border: 0.1875rem solid #404040;
            border-radius: 0.7rem;

            .content {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        ::placeholder {
            color: #b4b4b4;
        }
    }
}

// .select-overlay {
//     display: none;
// }