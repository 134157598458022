.tabslist-container{
    display: flex;
    width: 100%;
    height: 2.9375rem;
    margin-left: 0.1875rem;

    .tabslist-content{
        overflow: hidden;
        width: 100%;
        border-radius: 0.5rem;
        display: flex;
        border-width: 1px;
        border-color: rgba(221, 221, 221, 1);
        padding-bottom: 0.125rem;

        .tabslist-content1{
            width: 100%;
            border-bottom-right-radius: 0.375rem; 
            border-bottom-left-radius: 0.375rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .tabslist-content2{
                display: flex;
                align-items: center;

                .tabslist-content3{
                    font-size: 0.875rem;
                    letter-spacing: -0.025em;
                    font-family: SpoqaHanSansNeoMedium;
                    display: flex;
                    height: 0.875rem;
                    align-items: center;
                    padding-top: 0.1875rem;
                }
            }
        }
    }
}